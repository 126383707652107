<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          {{ $t("STORE_FOOD_TESTS") + " Храни : " + storeName }}
        </div>

        <div class="card-toolbar">
          <v-dialog :retain-focus="false" v-model="dialogAdd" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon2-plus"></i>
                {{ $t("ADD_NEW") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5"
                  >Дата: {{ today }} <br />
                  Обект: {{ storeName }}</span
                >
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="foodForTesting"
                        :label="$t('STORE_FOOD_TESTS')"
                        item-text="name"
                        item-value="id"
                        hide-details
                        v-model="form.foodId"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        :label="$t('TEMPERATURE')"
                        v-model="form.temperature"
                        hide-details
                        single-line
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save()"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-progress-circular
            v-show="isPDFloading"
            indeterminate
            color="green"
          ></v-progress-circular>
          <v-btn
            v-show="!isPDFloading"
            color="btn-success"
            class="btn btn-light-success font-weight-bolder mr-2 mt-3"
            @click="getPDF"
          >
            <i class="flaticon-download"></i>
            {{ $t("KKT") }}
          </v-btn>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :sort-desc="true"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingKitchenEquipmentTemperatures"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="kitchen-equipments-table"
        >
          <v-divider inset></v-divider>
          <template v-slot:top>
            <SearchTools
              :searchQuery.sync="searchQuery"
              @do-search="doSearch"
              @do-clear="doClear"
            >
              <template v-slot:prepend>
                <b-col>
                  <FormDatepickerBase
                    :type="'text'"
                    :clearable="true"
                    :i18n="{
                      label: 'USERS_SCHEDULE_DATE'
                    }"
                    :date.sync="filters.measuredOn"
                  ></FormDatepickerBase>
                </b-col>
                <b-col>
                  <v-autocomplete
                    hide-details
                    v-if="_.size(stores) > 0"
                    :items="stores"
                    :label="$t('STORE')"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-model="filters.storeId"
                    @click:clear="clearFilter('storeId')"
                  ></v-autocomplete>
                </b-col>
              </template>
            </SearchTools>
          </template>

          <template v-slot:item.foodName="{ item }">
            {{ item.foodName }}
          </template>

          <template v-slot:item.foodMethod="{ item }">
            {{ item.foodMethod }}
          </template>

          <template v-slot:item.foodDevice="{ item }">
            {{ item.foodDevice }}
          </template>

          <template v-slot:item.foodCookingTime="{ item }">
            {{ item.foodCookingTime }} минути
          </template>

          <template v-slot:item.temperature="{ item }">
            {{ item.temperature }}
          </template>

          <template v-slot:item.measuredOn="{ item }">
            {{ item.measuredOn | formatDateTime }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <v-dialog
                    v-model="dialogEdit"
                    max-width="900px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="false"
                        class="ml-1"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        dark
                        x-small
                        @click="edit(item)"
                      >
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{
                          $t("FOOD_TEST") + " - " + storeName
                        }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <v-select
                                :items="foodForTesting"
                                :label="$t('STORE_FOOD_TESTS')"
                                item-text="name"
                                item-value="id"
                                hide-details
                                v-model="editForm.foodId"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                :label="$t('TEMPERATURE')"
                                v-model="editForm.temperature"
                                hide-details
                                single-line
                                type="number"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="update()">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    :retain-focus="false"
                    v-model="dialogDelete"
                    max-width="500px"
                  >
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this
                        Temperature?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <div class="col-md-6 col-sm-12">
                  <v-btn class="mr-2" fab x-small>
                    <v-icon color="red" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer> </template>
    </b-card>
  </div>
</template>
<script>
import {
  FETCH_STORE_FOOD_TESTS,
  SAVE_STORE_FOOD_TEST,
  UPDATE_STORE_FOOD_TEST,
  DELETE_STORE_FOOD_TEST,
  GET_FOOD_SELECTS,
  STORE_FOOD_TESTS_TEMPERATURE_PDF
} from "@/modules/food-tests/store/food-tests.module";
//Fetch Temperatures
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import { mapGetters } from "vuex";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin";
import moment from "moment/moment";

export default {
  name: "StoreFoodTestsList",
  mixins: [permissionMixin, formBuilderMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      isPDFloading: false,
      firstLoader: true,
      search: "",
      dialogAdd: false,
      dialogEdit: false,
      dialogDelete: false,
      items: [],
      stores: [],
      foodForTesting: [],
      storeName: "",
      temperature: null,
      measuredOn: "",
      storeId: "",
      today: moment().format("YYYY-MM-DD HH:mm:ss"),
      totalItems: 100,
      searchQuery: "",
      form: {
        temperature: ""
      },
      editForm: {
        id: "",
        storeId: null,
        foodId: null,
        temperature: null
      },
      filters: {
        storeId: "",
        measuredOn: ""
      },
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      defaultItem: {
        id: "",
        storeId: null,
        foodId: null,
        temperature: null
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      headers: [
        { text: "Храна", value: "foodName", sortable: false },
        { text: "Метод", value: "foodMethod", sortable: false },
        { text: "Уред", value: "foodDevice", sortable: false },
        { text: "Време", value: "foodCookingTime", sortable: false },
        { text: "Обект", value: "storeName", sortable: false },
        { text: "Температура", value: "temperature", sortable: false },
        { text: "Измерена на", value: "measuredOn", sortable: false },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px"
        }
      ]
    };
  },
  components: {
    SearchTools,
    FormDatepickerBase
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Store Food Tests",
        route: { name: "list-store-food-tests" }
      },
      { title: "Store Food Tests List" }
    ]);
    vm.getSelectsStores();
    vm.getSelectsFoodForTesting();
  },
  computed: {
    ...mapGetters(["getStoreFoodTests", "isLoadingStoreFoodTests"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        storeId: this.filters.storeId,
        measuredOn: this.filters.measuredOn
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  watch: {
    dialogAdd(val) {
      val || this.close();
    },
    dialogEdit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    close() {
      let vm = this;
      vm.dialogAdd = false;
      vm.dialogEdit = false;
    },
    closeDelete() {
      let vm = this;
      vm.dialogDelete = false;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let vm = this;
      this.$store
        .dispatch(DELETE_STORE_FOOD_TEST, vm.defaultItem.id)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            this.closeDelete();
            this.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.$router.push({ name: "list-stores" });
          }
        });
      this.doSearch();
    },
    edit(item) {
      this.editForm = Object.assign({}, item);
      this.dialogEdit = true;
    },
    save() {
      let vm = this;
      let storeId;

      if (vm.filters.storeId === "" || vm.filters.storeId === null) {
        storeId = vm.stores[0].id;
      } else {
        storeId = vm.filters.storeId;
      }

      let payload = {
        store_id: storeId,
        food_id: vm.form.foodId,
        temperature: vm.form.temperature,
        measuredOn: moment().format("YYYY-MM-DD HH:mm:ss")
      };

      this.$store
        .dispatch(SAVE_STORE_FOOD_TEST, payload)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            vm.form.temperature = "";
            vm.close();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    update() {
      let vm = this;
      let id = vm.editForm.id;
      let payload = {
        store_id: vm.editForm.storeId,
        food_id: vm.editForm.foodId,
        temperature: vm.editForm.temperature
      };

      this.$store
        .dispatch(UPDATE_STORE_FOOD_TEST, {
          id: id,
          payload: payload
        })
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            vm.close();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.storeName = vm.stores[0].name;
      vm.filters.measuredOn = "";
      vm.measuredOnDate = moment().format("YYYY-MM-DD");
      vm.searchQuery = "";
      vm.filters.measuredOn = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getPDF() {
      let vm = this;
      vm.isPDFloading = true;
      //copy current params to modify
      vm.isLoadingStoreDailyTasks = true;
      let datePDF =
        vm.filters.measuredOn != null || vm.filters.measuredOn != ""
          ? vm.filters.measuredOn
          : moment().format("MM-DD-YYYY");
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(STORE_FOOD_TESTS_TEMPERATURE_PDF, apiParams)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "store-tasks-" + vm.storeName + "-" + datePDF;
            link.click();
            link.remove();
            vm.dialogPDF = false;
            if (vm.isPageLoading == true || vm.isPDFloading == true) {
              vm.isPageLoading = false;
              vm.isPDFloading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getDataFromApi() {
      let vm = this;

      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORE_FOOD_TESTS, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            vm.createStoreWorkingDateStrings();
            vm.fetchStoreName();
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getSelectsFoodForTesting() {
      let vm = this;
      this.$store
        .dispatch(GET_FOOD_SELECTS)
        .then(data => {
          vm.foodForTesting = data.data;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getSelectsStores() {
      let vm = this;
      let payload = {
        stores: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.stores = data.data.stores;
          vm.filters.storeId = vm.stores[0].id;
          vm.createStoreWorkingDateStrings();
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    createStoreWorkingDateStrings() {
      let vm = this;
      if (vm.filters.measuredOn !== null && vm.filters.measuredOn !== "") {
        vm.measuredOnDate = vm.filters.measuredOn;
      } else {
        vm.measuredOnDate = moment().format("YYYY-MM-DD");
      }
    },
    fetchStoreName() {
      let vm = this;
      if (vm.filters.storeId == "" || vm.filters.storeId == null) {
        if (vm.stores.length != 0) {
          vm.storeName = vm.stores[0].name;
        }
      } else {
        vm.storeName = vm.stores.find(function(store) {
          return store.id === vm.filters.storeId;
        }).name;
      }
    }
  }
};
</script>
